.ca{
  border: solid;
  border-color: orange;
  border-width: 3.5px;
  border-radius: 10%;
  width: 275px;
  height: 250px;
}
.search {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Noto Sans Thai", sans-serif;
}
.search-card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 80%;
  font-size: 22px;
}

.search-card-container .card {
  text-align: center;
}

.search-card-container .card-img-top {
  max-width: 100%;
}

.search-form {
  width: 100%;
}
.mid button {
  border: none;
  border-radius: 5px;
}
.search-form .top {
  display: flex;
  margin-bottom: 5px;
}
.search select {
  margin: 5px;
  padding: 3px;
  border-radius: 10px;
}
.search-form .mid {
  display: flex;
  flex-direction: row;
}
.people-nearby .google-maps {
  background: #f8f8f8;
  border-radius: 4px;
  border: 1px solid #f1f2f2;
  padding: 20px;
  margin-bottom: 20px;
}
.profile a {
  text-decoration: none;
}

.people-nearby .google-maps .map {
  height: 300px;
  width: 100%;
  border: none;
}

.search-form {
  text-align: center;
  padding: 20px;
}
.people-nearby .nearby-user {
  padding: 20px 0;
  border-top: 1px solid #f1f2f2;
  border-bottom: 1px solid #f1f2f2;
  margin-bottom: 20px;
}

img.profile-photo-lg {
  height: 80px;
  width: 80px;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .cas{
    width: 100%;
  }
  .search-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    gap: 5px;
  }
}

@media ((min-width: 768px) and (max-width: 1023px)) {
  .search-card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: 100%;
  }
}
